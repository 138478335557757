// @ts-nocheck
import React, { useState, useEffect } from 'react';
import MultiSelect from 'react-select';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { FcQuestionsEnum, TherapyTypes, TaskTypesEnum } from 'constants/enums';
import { genderList } from 'constants/lists';
import { notifySuccess } from 'actions/action-notifications';
import { RulesEngineClient, IAddRulePayload } from 'clients/rules-engine-client';
import { EditDelete } from 'components/icons/icons';
import TreeSelect from 'components/form/tree-select/tree-select';
import {
  InterventionCategoryId,
  InterventionCategoryName,
} from 'interfaces/enums/InterventionCategory';

import { IDiagnosis } from 'components/problems/interfaces';

import { InterventionTypeId, InterventionTypeName } from 'interfaces/enums/InterventionType';
import { getTreeMapFromDataTypes } from 'services/utils/data-collect';
import {
  Button,
  TextField,
  MenuItem,
  Stack,
  Grid,
  Typography,
  Divider,
  Box,
  Chip,
  InputLabel,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { convertToArborDate } from 'models/time/arbor-date';
import { useTypedSelector } from 'hooks/use-typed-selector';
import {
  ControlledText,
  ControlledDatePicker,
  ControlledDropdown,
  ControlledCheckbox,
  ControlledMultipleDropdown,
} from 'components/react-hook-form-fields';
import { useStyles } from './add-rule-modal.styles';

import {
  ActionTypeNames,
  FcQuestionsIdsMap,
  ConstraintTypeNames,
  EventTypeEnum,
  IRuleEngine,
  IGenericOption,
  AllEventTypes,
  TherapyLevelEventTypes,
  ActionTypeNamesEnum,
} from './types';
import { EventTypeOptions } from '../../../../constants/rules-engine';
import { ApplicationManagerClient } from '../../../../clients/application-manager-client';
import { windowFeatureIsEnabled } from '../../../../config/window-features';

// Types

enum NumberOperatorsEnum {
  Equal = '=',
  NotEqual = '≠',
  Greater = '>',
  GreaterOrEqual = '>=',
  Minor = '<',
  MinorOrEqual = '<=',
}

enum ValidActionEnum {
  Question = 'question',
}

enum EqualNotEqualOperatorsEnum {
  Equal = '=',
  NotEqual = '≠',
}

enum ValidActionFieldsIdsEnum {
  Question = 'question_id',
}

type QuestionActionFieldType =
  | { id: ValidActionFieldsIdsEnum; value: number }
  // | { id: 'show' | 'hide'; value: number };
  | { id: string; value: number };

interface IActionPayload {
  action_id: ValidActionEnum;
  fields: QuestionActionFieldType[];
}

enum FormModeEnum {
  Add,
  Edit,
}

interface IProps {
  rule?: IRuleEngine | null;
  onCloseModal?: () => void;
  onSave?: () => void;
  formMode?: FormModeEnum;
}

type Inputs = Pick<IRuleEngine, 'name' | 'selected_trigger' | 'effective_date'> &
  Partial<
    Pick<IRuleEngine, 'selected_conditions' | 'selected_actions' | 'status' | 'expiration_date'> &
      Partial<{
        condition_customers_ids: IGenericOption[];
        condition_clinical_support_status_id: number;
        condition_dispensing_status_id: number;
        selected_action_id: number;
        selected_task_option_id: number;
        condition_therapy_type: IGenericOption[];
        condition_operator_eq_customers_ids: IGenericOption;
      }>
  > &
  Record<string, number>;

// /Types
// Inputs Values
const extendedFcQuestions = {
  ...Object.entries(FcQuestionsEnum)
    .filter(item => {
      return !(
        !windowFeatureIsEnabled('rems_authorization_code') &&
        item === FcQuestionsEnum.rems_authorization_code
      );
    })
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
  wk_pp_received: 'Were WK and PP received ?',
};

const statuses = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];

const triggersOptions: { label: string; value: EventTypeEnum }[] = EventTypeOptions;

const dispenseStatusesEnrollmentOptions = [
  { label: 'Opt In', value: 2 },
  { label: 'Opt Out', value: 3 },
  { label: 'Undecided - Not yet offered to patient', value: 1 },
  { label: 'Undecided - Patient deferred decision', value: 4 },
];
const fcQuestionsShowHideOptions = [
  { label: 'Hide', value: 0 },
  { label: 'Show', value: 1 },
];
const fcQuestions = Object.entries(extendedFcQuestions).map(([key, value]) => ({
  label: value,
  value: key,
}));
const therapyTypesOptions = [
  { label: 'Non Specialty', value: TherapyTypes.NonSpecialty },
  { label: 'Specialty', value: TherapyTypes.Specialty },
  { label: 'Chronic Disease Management', value: TherapyTypes.ChronicDiseaseManagement },
  { label: 'Specialty Lite', value: TherapyTypes.SpecialtyLite },
  { label: 'Specialty Provider Administered', value: TherapyTypes.SpecialtyProviderAdministered },
];

const yesNoOptions = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
];

const conditionsToAdd = [
  { label: 'Site', value: 'customer_id_filter', validEventTypes: AllEventTypes },
  {
    label: 'Patient Gender',
    value: 'patient_gender',
    validEventTypes: AllEventTypes,
  },
  {
    label: 'Patient Age',
    value: 'patient_age',
    validEventTypes: AllEventTypes,
  },
  {
    label: 'Patient Special Population',
    value: 'patient_special_population',
    validEventTypes: AllEventTypes,
  },
  {
    label: 'Patient Preference - Received Welcome Kit',
    value: 'patient_welcome_kit',
    validEventTypes: AllEventTypes,
  },
  {
    label: 'Patient Preference - Adherence Packaging',
    value: 'patient_packaging',
    validEventTypes: AllEventTypes,
  },
  {
    label: 'Patient Preference - Medication Sync',
    value: 'patient_med_sync',
    validEventTypes: AllEventTypes,
  },
  {
    label: 'Patient Documents Uploaded - Welcome Kit And Patient Privacy Documents',
    value: 'welcome_kit_and_patient_privacy_docs_uploaded',
    validEventTypes: AllEventTypes,
  },
  { label: 'Therapy Type', value: 'therapy_type', validEventTypes: AllEventTypes },
  {
    label: 'Therapy Dispensing Status',
    value: 'therapy_dispensing_status',
    validEventTypes: AllEventTypes,
  },
  {
    label: 'Therapy Clinical Support Status',
    value: 'therapy_clinical_support_status',
    validEventTypes: AllEventTypes,
  },
  {
    label: 'Therapy GPI 10',
    value: 'therapy_gpi10_constraint',
    validEventTypes: AllEventTypes,
  },
  {
    label: 'Therapy GPI 12',
    value: 'therapy_gpi12_constraint',
    validEventTypes: AllEventTypes,
  },
  {
    label: 'Therapy GPI 14',
    value: 'therapy_gpi14_constraint',
    validEventTypes: AllEventTypes,
  },
  {
    label: 'Therapy Disease State',
    value: 'therapy_disease_state',
    validEventTypes: AllEventTypes,
  },
  {
    label: 'Therapy Fill Cycle',
    value: 'therapy_fill_cycle',
    validEventTypes: TherapyLevelEventTypes,
  },
];

const actionsToAdd = [
  { label: 'Show/Hide FC Question', value: ActionTypeNamesEnum.ShowHideQuestion },
  { label: 'Create Task', value: ActionTypeNamesEnum.CreateTask },
];

// /Inputs Values

// Consts
const RequiredInputErrorMessage = 'Required';
const AllSitesOptionValue = 0;

const AllSitesOption = {
  label: 'All Sites',
  value: AllSitesOptionValue,
  isDisabled: false,
};
// /Consts

export const AddRuleForm = (props: IProps): JSX.Element | null => {
  const { formMode = FormModeEnum.Add } = props;
  const classes: any = useStyles();
  const dispatch = useDispatch();
  const clinicalDataTypes = useTypedSelector(state => state.lookups.clinicalDataTypes);
  const clinicalDataItemsToSelect = getTreeMapFromDataTypes(clinicalDataTypes);
  const [diseaseStateOptions, setDiseaseStateOptions] = useState<
    { label: string; value: number }[]
  >([]);

  // Form setup
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
    watch,
  } = useForm<any>({
    defaultValues: {} as Inputs,
  });
  const onErrors = (_errors: any) => console.log(_errors);
  const formStateValues = getValues();
  // /Form setup

  const customers = useTypedSelector(state => state.filters.customers);
  const lookups = useTypedSelector(state => state.lookups);
  const spPopulationOptions = lookups.specialPopulations.map(({ id, type }) => ({
    value: id,
    label: type,
  }));

  const [blockAllSites, setBlockAllSites] = useState<boolean>(false);
  const [selectedClinicalDataItems, setSelectedClinicalDataItems] = useState<
    Array<IGenericOption & { required: boolean }>
  >([]);

  const [sitesOptions, setSitesOptions] = useState(
    (customers || []).map(({ name, id }) => ({
      label: name,
      value: id,
    })) as IGenericOption[],
  );

  useEffect(() => {
    if (customers.length > 0) {
      setSitesOptions(
        (customers || []).map(({ name, id }) => ({
          label: name,
          value: Number(id),
          isDisabled: false,
        })) as IGenericOption[],
      );
      sitesOptions.splice(0, 0, AllSitesOption);
    }
  }, [customers]);

  useEffect(() => {
    setSitesOptions(
      sitesOptions.map((option: IGenericOption) => ({
        ...option,
        isDisabled: !!blockAllSites && option.value !== AllSitesOptionValue,
      })),
    );
    if (blockAllSites) {
      setValue('condition_customers_ids', [AllSitesOption]);
    }
  }, [blockAllSites]);

  const eqNotEqOperatorOptions = [
    {
      label: EqualNotEqualOperatorsEnum.Equal,
      value: EqualNotEqualOperatorsEnum.Equal,
    },
    {
      label: EqualNotEqualOperatorsEnum.NotEqual,
      value: EqualNotEqualOperatorsEnum.NotEqual,
    },
  ];

  const ageOperatorOptions = [
    {
      label: NumberOperatorsEnum.Equal,
      value: NumberOperatorsEnum.Equal,
    },
    {
      label: NumberOperatorsEnum.Greater,
      value: NumberOperatorsEnum.Greater,
    },
    {
      label: NumberOperatorsEnum.GreaterOrEqual,
      value: NumberOperatorsEnum.GreaterOrEqual,
    },
    {
      label: NumberOperatorsEnum.Minor,
      value: NumberOperatorsEnum.Minor,
    },
    {
      label: NumberOperatorsEnum.MinorOrEqual,
      value: NumberOperatorsEnum.MinorOrEqual,
    },
  ];
  const [beforeSendErrors, setBeforeSendErrors] = useState<{ [key: string]: string }>({});

  const taskTypesToCreateOptions = Object.entries([
    TaskTypesEnum.DUR,
    TaskTypesEnum.INT,
    TaskTypesEnum.DC,
    TaskTypesEnum.CSL,
    TaskTypesEnum.PA,
    TaskTypesEnum.FA,
    TaskTypesEnum.RS,
  ])
    .map(([_, value]) => ({
      value,
      label: value,
    }))
    .filter(({ label }) => !!label);

  const intCategoriesOptions = Object.entries(InterventionCategoryId)
    .map(([key, value]) => ({
      value,
      label: Object.values(InterventionCategoryName)?.find(categoryName => categoryName === key),
    }))
    .filter(({ label }) => !!label);

  const intTypeOptions = Object.entries(InterventionTypeId)
    .map(([key, value]) => ({
      value,
      label: Object.values(InterventionTypeName)?.find(
        categoryName => categoryName.localeCompare(key, undefined, { sensitivity: 'base' }) === 0,
      ),
    }))
    .filter(({ label }) => !!label);

  type DynamicControlledInput = {
    shown: boolean;
    order?: number;
    id?: number;
  };

  const [shownConditions, setShownConditions] = useState<
    Record<ConstraintTypeNames, DynamicControlledInput>
  >({
    customer_id_filter: { shown: false },
    therapy_dispensing_status: { shown: false },
    therapy_clinical_support_status: { shown: false },
    patient_welcome_kit: { shown: false },
    patient_packaging: { shown: false },
    patient_med_sync: { shown: false },
    patient_special_population: { shown: false },
    therapy_type: { shown: false },
    welcome_kit_and_patient_privacy_docs_uploaded: { shown: false },
    therapy_gpi10_constraint: { shown: false },
    therapy_gpi12_constraint: { shown: false },
    therapy_gpi14_constraint: { shown: false },
  });

  const [showAddConditionDropdown, setShowAddConditionDropdown] = useState<boolean>(false);

  const [showAddActionDropdown, setShowAddActionDropdown] = useState<boolean>(false);
  const [shownActions, setShownActions] = useState<
    Record<ActionTypeNames | string, DynamicControlledInput>
  >({});

  const getNextOrderForQuestionArray = (actionsArray: Record<string, DynamicControlledInput>) => {
    return (
      Object.entries(actionsArray)
        .filter(
          ([key, _]) =>
            key.includes(ActionTypeNamesEnum.ShowHideQuestion) ||
            key.includes(ActionTypeNamesEnum.CreateTask),
        )
        .reduce(
          (acc, [_, value]) => (value.order !== undefined && acc < value.order ? value.order : acc),
          0,
        ) + 1
    );
  };

  const getResultingCustomerIds = (
    dataCustomersIds: number[],
    eqOperatorSelected: EqualNotEqualOperatorsEnum,
  ) => {
    const siteEqualitySiteSelected: EqualNotEqualOperatorsEnum = eqOperatorSelected;
    let selectedCustomers: number[] = [];
    const selectedCustomersIds: number[] = dataCustomersIds;

    const allCustomersSelected = dataCustomersIds.some(id => id === AllSitesOptionValue);

    if (allCustomersSelected) {
      // All customers selected
      if (siteEqualitySiteSelected === EqualNotEqualOperatorsEnum.Equal) {
        selectedCustomers = customers.map(({ id }) => id);
      } else {
        // None of the customers
      }
    }

    if (!allCustomersSelected) {
      // Specific customers selected
      if (siteEqualitySiteSelected === EqualNotEqualOperatorsEnum.Equal) {
        selectedCustomers = selectedCustomersIds;
      } else {
        selectedCustomers = customers
          .filter(
            ({ id }) => !selectedCustomersIds.some(selectedCustomerId => selectedCustomerId === id),
          )
          .map(({ id }) => id);
      }
    }
    return selectedCustomers;
  };

  const onChangeSiteOptions = (event: IGenericOption[]) => {
    setBlockAllSites(event?.some(({ value }) => value === AllSitesOptionValue));
  };

  const onSubmit: SubmitHandler<Inputs> = async data => {
    const payload: IAddRulePayload = {
      name: data.name,
      event_type: data.selected_trigger,
      start_dt: convertToArborDate(data.effective_date).getUtcDatetime() || '',
      end_dt: convertToArborDate(data.expiration_date).getUtcDatetime(),
      constraints: [],
      actions: [],
    };

    const mapConstraintsKeysToUiConditions = {
      operator_eq_customers_ids: 'customer_id_filter',
      customers_ids: 'customer_id_filter',
      therapy_types: 'therapy_type',
      patient_privacy_documents_uploaded: 'patient_document_label',
      dispensing_status_id: 'therapy_dispensing_status',
      clinical_support_status_id: 'therapy_clinical_support_status',
      patient_welcome_kit: 'patient_welcome_kit',
      patient_packaging: 'patient_packaging',
      patient_special_population: 'patient_special_population',
      patient_med_sync: 'patient_med_sync',
      patient_gender: 'patient_gender',
      patient_privacy_documents_uploaded: 'welcome_kit_and_patient_privacy_docs_uploaded',
      therapy_disease_state: 'therapy_disease_state',
      therapy_gpi10_constraint: 'therapy_gpi10_constraint',
      therapy_gpi12_constraint: 'therapy_gpi12_constraint',
      therapy_gpi14_constraint: 'therapy_gpi14_constraint',
      patient_age_operator_left_side_input: 'patient_age',
      patient_age_operator_right_side_input: 'patient_age',
      patient_age_operator_left_side: 'patient_age',
      patient_age_operator_right_side: 'patient_age',
      therapy_fill_cycle_operator_left_side: 'therapy_fill_cycle',
      therapy_fill_cycle_operator_right_side: 'therapy_fill_cycle',
      therapy_fill_cycle_operator_left_side_input: 'therapy_fill_cycle',
      therapy_fill_cycle_operator_right_side_input: 'therapy_fill_cycle',
    };

    // Regular expression patterns
    const questionIdRegex = /^show_hide_question_(?!.*_option_selected$)(\d+)$/;
    const questionOptionSelectedRegex = /^show_hide_question_(\d+)_option_selected$/;
    const listClinicalDataItemSelectedRegex = /^list_clinical_data_item_required_[\d]+-(\d+)$/;
    const createTaskOptionSelectedRegex = /^create_task_(\d+)_type_selected$/;

    const conditionRegex = /^condition_\s*(.*?)$/;

    const isInShownConditions = conditionId =>
      Object.entries(shownConditions).some(([conditionKeyName, conditionValue]) => {
        if (conditionValue.shown) {
          return conditionKeyName === mapConstraintsKeysToUiConditions[conditionId];
        }
        return false;
      });

    const isInShownActions = actionId => {
      if (actionId.includes(ActionTypeNamesEnum.ShowHideQuestion)) {
        // Test if question is shown
        const match = actionId.match(questionIdRegex);
        if (match && match?.length > 0) {
          const questionId = match[1];
          return Object.entries(shownActions).some(
            ([actionKeyName, actionValue]) =>
              actionKeyName.includes(ActionTypeNamesEnum.ShowHideQuestion) &&
              Number(actionValue.id) === Number(questionId) &&
              actionValue.shown,
          );
        }
      }

      if (actionId.includes(ActionTypeNamesEnum.CreateTask)) {
        // Test if task is shown
        const match = actionId.match(createTaskOptionSelectedRegex);
        if (match && match?.length > 0) {
          const taskId = match[1];
          return Object.entries(shownActions).some(
            ([actionKeyName, actionValue]) =>
              actionKeyName.includes(ActionTypeNamesEnum.CreateTask) &&
              Number(actionValue.id) === Number(taskId) &&
              actionValue.shown,
          );
        }
      }
      return false;
    };

    payload.constraints =
      Object.entries(data)
        .filter(([_, value]) => !!value)
        .map(([key, value]) => {
          if (conditionRegex.test(key)) {
            const match = key.match(conditionRegex);
            const conditionId = match ? match[1] : null;

            if (conditionId && value && isInShownConditions(conditionId)) {
              switch (conditionId) {
                case 'customers_ids':
                  return {
                    constraint_id: 'customer_id_filter',
                    fields: [
                      {
                        id: 'customer_id',
                        value: getResultingCustomerIds(
                          (value as IGenericOption[])?.map(({ value: selectedCustomerValue }) =>
                            Number(selectedCustomerValue),
                          ),
                          String(
                            data.condition_operator_eq_customers_ids?.value,
                          ) as EqualNotEqualOperatorsEnum,
                        ),
                      },
                    ],
                  };
                case 'therapy_types':
                  return {
                    constraint_id: 'therapy_type',
                    fields: [
                      {
                        id: 'therapy_type',
                        value: (value as IGenericOption[])?.map(
                          ({ value: selectedTherapyTypeValue }) => Number(selectedTherapyTypeValue),
                        ),
                      },
                    ],
                  };
                case 'dispensing_status_id':
                  return {
                    constraint_id: 'therapy_dispensing_status',
                    fields: [
                      {
                        id: 'dispensing_status_id',
                        value: value.map(opt => opt.value),
                      },
                    ],
                  };
                case 'clinical_support_status_id':
                  return {
                    constraint_id: 'therapy_clinical_support_status',
                    fields: [
                      {
                        id: 'clinical_support_status_id',
                        value: value.map(opt => opt.value),
                      },
                    ],
                  };
                case 'patient_welcome_kit':
                  return {
                    constraint_id: 'patient_welcome_kit',
                    fields: [{ id: 'has_welcome_kit', value }],
                  };
                case 'patient_packaging':
                  return {
                    constraint_id: 'patient_packaging',
                    fields: [{ id: 'med_packaging', value }],
                  };
                case 'patient_special_population':
                  return {
                    constraint_id: 'patient_special_population',
                    fields: [
                      { id: 'special_population_id', value: value.map(({ value }) => value) },
                    ],
                  };
                case 'patient_med_sync':
                  return {
                    constraint_id: 'patient_med_sync',
                    fields: [{ id: 'patient_med_sync', value }],
                  };
                case 'patient_gender':
                  return {
                    constraint_id: 'patient_gender',
                    fields: [{ id: 'patient_gender', value: value.value }],
                  };
                case 'patient_privacy_documents_uploaded':
                  return {
                    constraint_id: 'patient_document_label',
                    fields: [
                      {
                        id: 'document_label',
                        value: ['Welcome Kit', 'Notice of Privacy Practices'],
                      },
                      { id: 'document_exists', value },
                    ],
                  };
                case 'therapy_disease_state':
                  return {
                    constraint_id: 'therapy_disease_state',
                    fields: [
                      {
                        id: 'disease_state',
                        value:
                          data.condition_operator_eq_therapy_disease_state ===
                          EqualNotEqualOperatorsEnum.Equal
                            ? value
                            : diseaseStateOptions
                                .filter(option => !value.includes(option.value))
                                .map(option => option.value),
                      },
                    ],
                  };
                case 'therapy_gpi10_constraint':
                  return {
                    constraint_id: 'therapy_gpi10_constraint',
                    fields: [{ id: 'gpi', value }],
                  };
                case 'therapy_gpi12_constraint':
                  return {
                    constraint_id: 'therapy_gpi12_constraint',
                    fields: [{ id: 'gpi', value }],
                  };
                case 'therapy_gpi14_constraint':
                  return {
                    constraint_id: 'therapy_gpi14_constraint',
                    fields: [{ id: 'gpi', value }],
                  };
                default:
                  break;
              }
            }
          }
          return null;
        })
        .filter(value => value !== null) || [];

    // Attach patient age condition
    const patientAgeData = {
      leftOperator: data.condition_patient_age_operator_left_side?.value || null,
      leftValue: data.condition_patient_age_operator_left_side_input || null,
      rightOperator: data.condition_patient_age_operator_right_side?.value || null,
      rightValue: data.condition_patient_age_operator_right_side_input || null,
    };

    const patientAgeCondition = {
      constraint_id: 'patient_age',
    };

    if (patientAgeData.leftOperator && isInShownConditions('patient_age_operator_left_side')) {
      payload.constraints.push({
        ...patientAgeCondition,
        fields: [
          { id: 'patient_age_value', value: patientAgeData.leftValue },
          { id: 'patient_age_operator', value: patientAgeData.leftOperator },
        ],
      });
    }

    if (patientAgeData.rightOperator && isInShownConditions('patient_age_operator_right_side')) {
      payload.constraints.push({
        ...patientAgeCondition,
        fields: [
          { id: 'patient_age_value', value: patientAgeData.rightValue },
          { id: 'patient_age_operator', value: patientAgeData.rightOperator },
        ],
      });
    }

    // Attach therapy fill cycle
    const therapyFillCycleData = {
      leftOperator: data.condition_therapy_fill_cycle_operator_left_side?.value || null,
      leftValue: data.condition_therapy_fill_cycle_operator_left_side_input || null,
      rightOperator: data.condition_therapy_fill_cycle_operator_right_side?.value || null,
      rightValue: data.condition_therapy_fill_cycle_operator_right_side_input || null,
    };

    const therapyFillCycleCondition = {
      constraint_id: 'therapy_fill_cycle',
    };

    if (
      therapyFillCycleData.rightOperator &&
      isInShownConditions('therapy_fill_cycle_operator_right_side')
    ) {
      payload.constraints.push({
        ...therapyFillCycleCondition,
        fields: [
          { id: 'therapy_fill_cycle_value', value: therapyFillCycleData.rightValue },
          { id: 'therapy_fill_cycle_operator', value: therapyFillCycleData.rightOperator },
        ],
      });
    }

    if (
      therapyFillCycleData.leftOperator &&
      isInShownConditions('therapy_fill_cycle_operator_left_side')
    ) {
      payload.constraints.push({
        ...therapyFillCycleCondition,
        fields: [
          { id: 'therapy_fill_cycle_value', value: therapyFillCycleData.leftValue },
          { id: 'therapy_fill_cycle_operator', value: therapyFillCycleData.leftOperator },
        ],
      });
    }

    // Attach Question Actions
    payload.actions =
      Object.values(
        Object.entries(data)
          .filter(([key, value]) => !!value)
          .filter(([key, value]) => key.includes(ActionTypeNamesEnum.ShowHideQuestion))
          // @ts-ignore
          .reduce((acc, elem) => {
            const [key, value] = elem;
            if (questionIdRegex.test(key)) {
              const match = key.match(questionIdRegex);
              if (match && match?.length > 0) {
                const questionId = match[1];
                const specificQuestionObject = {
                  id: ValidActionFieldsIdsEnum.Question,
                  // @ts-ignore
                  value: FcQuestionsIdsMap[String(value)],
                };
                const questionObj = {
                  action_id: ValidActionEnum.Question,
                  fields: [specificQuestionObject],
                };
                // @ts-ignore
                if (acc[questionId]) {
                  // @ts-ignore
                  acc[questionId].fields = [...acc[questionId].fields, specificQuestionObject];
                } else {
                  return {
                    ...acc,
                    [questionId]: questionObj,
                  };
                }
              }
            } else {
              const match = key.match(questionOptionSelectedRegex);
              if (match && match?.length > 0) {
                const questionId = match[1];
                const showOrHideObject =
                  value === 1 ? { id: 'hide', value: 0 } : { id: 'hide', value: 1 };
                // @ts-ignore
                if (acc[questionId]) {
                  // @ts-ignore
                  acc[questionId].fields = [...acc[questionId].fields, showOrHideObject];
                } else {
                  const questionObj = {
                    action_id: ValidActionEnum.Question,
                    fields: [showOrHideObject],
                  };
                  return {
                    ...acc,
                    [questionId]: questionObj,
                  };
                }
              }
            }
            return acc;
          }, {}),
      )
        .map(value => value)
        .filter(value => value !== null) || [];

    // Attach Create Task Actions
    payload.actions = [
      ...payload.actions,
      ...Object.entries(data)
        .filter(([_, value]) => !!value)
        .filter(([key, _]) => key.includes(ActionTypeNamesEnum.CreateTask) && isInShownActions(key))
        .map(elem => {
          const [key, taskType] = elem;
          if (createTaskOptionSelectedRegex.test(key)) {
            const taskTypeActionMap = {
              [TaskTypesEnum.PA]: ActionTypeNamesEnum.CreatePriorAuthorizationTask,
              [TaskTypesEnum.FA]: ActionTypeNamesEnum.CreateFinancialAssistanceTask,
              [TaskTypesEnum.CSL]: ActionTypeNamesEnum.CreateCounselingTask,
              [TaskTypesEnum.DUR]: ActionTypeNamesEnum.CreateDrugUtilizationReviewTask,
              [TaskTypesEnum.RS]: ActionTypeNamesEnum.CreateRiskStrategyTask,
              [TaskTypesEnum.INT]: ActionTypeNamesEnum.CreateIntTask,
            };

            if (taskTypeActionMap[taskType]) {
              const createActionFields =
                taskType === TaskTypesEnum.INT
                  ? [
                      {
                        id: 'category_id',
                        value: data.create_int_task_category_selected,
                      },
                      {
                        id: 'type_id',
                        value: data.create_int_task_type_selected,
                      },
                    ]
                  : [];

              return {
                action_id: taskTypeActionMap[taskType],
                fields: createActionFields,
              };
            }
          }
          return null;
        })
        .filter(elem => !!elem),
    ];

    // Attach DC create task action

    const createDcTaskActionDataItems = Object.entries(data)
      .filter(([_, value]) => value !== null)
      .filter(([key]) => key.includes('list_clinical_data_item_required_'))
      .reduce((acc, elem) => {
        const [key, checked] = elem;
        if (listClinicalDataItemSelectedRegex.test(key)) {
          const match = key.match(listClinicalDataItemSelectedRegex);
          if (match && match?.length > 0) {
            const itemId = match[1];
            return [...acc, { clinicalDataTypeId: itemId, required: checked }];
          }
        }
      }, []);

    if (createDcTaskActionDataItems.length > 0) {
      payload.actions.push({
        action_id: 'create_data_collect_task',
        fields: [
          {
            id: 'data_collect',
            value: JSON.stringify(createDcTaskActionDataItems),
          },
          {
            id: 'frequency_value',
            value: data.dc_action_frequency_value,
          },
          {
            id: 'frequency_unit',
            value: data.dc_action_frequency_unit,
          },
        ],
      });
    }

    const customErrorMessages = {};
    if (payload.actions.length === 0) {
      customErrorMessages.action = RequiredInputErrorMessage;
    }
    if (payload.constraints.length === 0) {
      customErrorMessages.condition = RequiredInputErrorMessage;
    }

    if (customErrorMessages !== {}) {
      setBeforeSendErrors({ ...beforeSendErrors, ...customErrorMessages });
    } else {
      const { condition, action, ...restErrors } = beforeSendErrors;
      setBeforeSendErrors(restErrors || {});
    }

    if (!customErrorMessages.action && !customErrorMessages.condition) {
      await RulesEngineClient.addRule(payload).then(response => {
        dispatch(notifySuccess('Rule Added Successfully'));
        if (props.onSave) {
          props.onSave();
        }
      });
    }
  };

  const onClickRemoveCondition = (constraintKeyName: ConstraintTypeNames) => {
    // @ts-ignore
    shownConditions[constraintKeyName] = null;
    setShownConditions(
      Object.fromEntries(
        Object.entries(shownConditions).filter(([_, value]) => value !== null),
      ) as Record<ConstraintTypeNames, DynamicControlledInput>,
    );
  };

  const onClickRemoveAction = (actionKeyName: ActionTypeNames) => {
    // @ts-ignore
    shownActions[actionKeyName] = null;
    setShownActions(
      Object.fromEntries(
        Object.entries(shownActions).filter(([_, value]) => value !== null),
      ) as Record<ActionTypeNames, DynamicControlledInput>,
    );
  };

  const renderAddConditionSection = () => {
    return (
      <TextField
        select
        variant="standard"
        className={classes.inputField}
        onChange={event => {
          setShownConditions({
            ...shownConditions,
            [event.target.value]: {
              shown: true,
              order: Object.values(shownConditions).filter(({ shown }) => shown).length,
            },
          });
          setShowAddConditionDropdown(false);
        }}
        label="Select Condition to Display"
      >
        {conditionsToAdd.map(option => (
          <MenuItem
            key={option.value}
            value={option.value}
            disabled={
              !!shownConditions[option.value as ConstraintTypeNames]?.shown ||
              !option?.validEventTypes?.includes(watch('selected_trigger'))
            }
          >
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  const renderAddActionSection = () => {
    return (
      <TextField
        select
        variant="standard"
        className={classes.inputField}
        onChange={event => {
          let actionKeyName = event.target.value;
          let actionId = 1;
          let orderNumber = 1;
          if (
            actionKeyName.includes(ActionTypeNamesEnum.ShowHideQuestion) ||
            actionKeyName.includes(ActionTypeNamesEnum.CreateTask)
          ) {
            const newQuestionId = getNextOrderForQuestionArray(shownActions);
            actionId = newQuestionId;
            orderNumber = newQuestionId;
            actionKeyName = `${actionKeyName}_${newQuestionId}`;
          }
          setShownActions({
            ...shownActions,
            [actionKeyName]: {
              shown: true,
              order: orderNumber,
              id: actionId,
            },
          });
          setShowAddActionDropdown(false);
        }}
        label="Select Action to Display"
      >
        {actionsToAdd.map(option => (
          <MenuItem
            key={option.value}
            value={option.value}
            disabled={!!shownActions[option.value as ActionTypeNames]?.shown}
          >
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  const renderClinicalSupportCondition = (): JSX.Element | null => {
    return (
      <Stack direction="row" spacing={1}>
        <Controller
          name="condition_clinical_support_status_id"
          control={control}
          render={(ctrlProps: any, ...restField) => (
            <>
              <InputLabel>Therapy Clinical Support Enrollment </InputLabel>
              <MultiSelect
                {...restField}
                isMulti
                onChange={(value: any) => setValue('condition_clinical_support_status_id', value)}
                options={dispenseStatusesEnrollmentOptions}
                className={classes.inputField}
                value={ctrlProps?.field?.value}
              />
            </>
          )}
        />
      </Stack>
    );
  };

  const renderDispenseCondition = (): JSX.Element | null => {
    return (
      <Stack direction="row" spacing={1}>
        <Controller
          name="condition_dispensing_status_id"
          control={control}
          render={(ctrlProps: any, ...restField) => (
            <>
              <InputLabel>Therapy Dispense Status Enrollment </InputLabel>
              <MultiSelect
                {...restField}
                isMulti
                onChange={(value: any) => setValue('condition_dispensing_status_id', value)}
                options={dispenseStatusesEnrollmentOptions}
                className={classes.inputField}
                value={ctrlProps?.field?.value}
              />
            </>
          )}
        />
      </Stack>
    );
  };

  const renderSpecialPopulationCondition = (): JSX.Element | null => {
    return (
      <Stack direction="row" alignItems="baseline" spacing={1}>
        <Typography>Patient Special Population</Typography>
        <Controller
          name="condition_patient_special_population"
          control={control}
          render={(ctrlProps: any, ...restField) => (
            <MultiSelect
              {...restField}
              isMulti
              onChange={(value: any) => setValue('condition_patient_special_population', value)}
              options={spPopulationOptions}
              className={classes.inputField}
              value={ctrlProps?.field?.value}
            />
          )}
        />
      </Stack>
    );
  };

  const handleFetchDiseaseStateOptions = async (): Promise<IDiagnosis[]> => {
    const response = await ApplicationManagerClient.fetchDiseaseStates();

    // Filtering codes that are already included in a patient problem
    return response.data?.result?.map(item => ({
      label: item.diseaseName,
      value: item.id,
    }));
  };

  useEffect(() => {
    handleFetchDiseaseStateOptions().then(options => {
      setDiseaseStateOptions(options);
    });
  }, []);

  const renderTherapyDiseaseStateCondition = (): JSX.Element | null => {
    return (
      <Grid container spacing={1}>
        <Grid xs={12} item>
          <Stack direction="row" alignItems="baseline" spacing={1}>
            <Typography>Disease State</Typography>
            <ControlledDropdown
              defaultValue={null}
              control={control}
              percentWith={30}
              name="condition_operator_eq_therapy_disease_state"
              label="Operator *"
              onChange={(value: any) =>
                setValue('condition_operator_eq_therapy_disease_state', value)
              }
              options={eqNotEqOperatorOptions}
              getOptionLabel={option => option.label}
              getOptionValue={option => option.value}
            />
            <ControlledMultipleDropdown
              defaultValue={null}
              control={control}
              name="condition_therapy_disease_state"
              label="Disease State *"
              onChange={(value: any) => setValue('condition_therapy_disease_state', value)}
              options={diseaseStateOptions}
              getOptionLabel={option => option.label}
              getOptionValue={option => option.value}
            />
          </Stack>
        </Grid>
      </Grid>
    );
  };

  const renderTherapyTypeCondition = (): JSX.Element | null => {
    return (
      <Stack direction="row" alignItems="baseline" spacing={1}>
        <Typography>Therapy Type</Typography>
        <Controller
          name="condition_therapy_types"
          control={control}
          render={(ctrlProps: any, ...restField) => (
            <MultiSelect
              {...restField}
              isMulti
              onChange={(value: any) => setValue('condition_therapy_types', value)}
              options={therapyTypesOptions}
              className={classes.inputField}
              value={ctrlProps?.field?.value}
            />
          )}
        />
      </Stack>
    );
  };

  const renderPatientPreferenceForWelcomeKitCondition = (): JSX.Element | null => {
    return (
      <Stack direction="row" alignItems="baseline" spacing={1}>
        <Typography>Patient Preference - Received Welcome Kit?</Typography>
        <Controller
          name="condition_patient_welcome_kit"
          control={control}
          render={(ctrlProps: any, ...restField) => (
            <TextField
              {...restField}
              select
              variant="standard"
              onChange={e => setValue('condition_patient_welcome_kit', e.target.value)}
              className={classes.inputField}
              value={ctrlProps?.field?.value}
            >
              {yesNoOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Stack>
    );
  };
  const renderPatientPreferenceForMedPacking = (): JSX.Element | null => {
    return (
      <Stack direction="row" alignItems="baseline" spacing={1}>
        <Typography>Patient Preference - Medication Packing</Typography>
        <Controller
          name="condition_patient_packaging"
          control={control}
          render={(ctrlProps: any, ...restField) => (
            <TextField
              {...restField}
              select
              variant="standard"
              onChange={e => setValue('condition_patient_packaging', e.target.value)}
              className={classes.inputField}
              value={ctrlProps?.field?.value}
            >
              {yesNoOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Stack>
    );
  };
  const renderPatientPreferenceForMedSync = (): JSX.Element | null => {
    return (
      <Stack direction="row" alignItems="baseline" spacing={1}>
        <Controller
          name="condition_patient_med_sync"
          control={control}
          render={(ctrlProps: any, ...restField) => (
            <>
              <InputLabel>Patient Preference - Medication Sync </InputLabel>
              <TextField
                {...restField}
                select
                variant="standard"
                className={classes.inputField}
                onChange={e => setValue('condition_patient_med_sync', e.target.value)}
                value={ctrlProps?.field?.value}
              >
                {dispenseStatusesEnrollmentOptions.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </>
          )}
        />
      </Stack>
    );
  };
  const renderPatientGenderCondition = (): JSX.Element | null => {
    return (
      <Stack direction="row" alignItems="baseline" spacing={1}>
        <Typography>Patient Gender</Typography>
        <Controller
          name="condition_patient_gender"
          control={control}
          xs={{ width: '20%' }}
          render={(ctrlProps: any, ...restField) => (
            <>
              <MultiSelect
                {...restField}
                options={genderList}
                className={classes.inputField}
                onChange={(value: any) => setValue('condition_patient_gender', value)}
                value={ctrlProps?.field?.value}
              />
            </>
          )}
        />
      </Stack>
    );
  };

  const renderPatientAgeCondition = (): JSX.Element | null => {
    return (
      <Stack direction="row" alignItems="baseline" spacing={1}>
        <Typography>Patient Age</Typography>
        <Controller
          name="condition_patient_age_operator_left_side"
          control={control}
          xs={{ width: '20%' }}
          render={(ctrlProps: any, ...restField) => (
            <>
              <MultiSelect
                {...restField}
                defaultValue={ageOperatorOptions[0].value}
                options={ageOperatorOptions}
                className={classes.inputField}
                onChange={(value: any) =>
                  setValue('condition_patient_age_operator_left_side', value)
                }
                value={ctrlProps?.field?.value}
              />
            </>
          )}
        />
        <ControlledText
          percentWith={20}
          control={control}
          name="condition_patient_age_operator_left_side_input"
          autocomplete={false}
          disabled={watch('condition_patient_age_operator_left_side') === null}
          validations={{ required: true }}
          inputMetaData={{ touched: Boolean(errors.name), error: RequiredInputErrorMessage }}
        />
        <Controller
          name="condition_patient_age_operator_right_side"
          control={control}
          xs={{ width: '20%' }}
          disabled={
            !watch('condition_patient_age_operator_left_side') ||
            !watch('condition_patient_age_operator_left_side_input')
          }
          render={(ctrlProps: any, ...restField) => (
            <>
              <MultiSelect
                {...restField}
                options={ageOperatorOptions}
                defaultValue={ageOperatorOptions[0].value}
                className={classes.inputField}
                onChange={(value: any) =>
                  setValue('condition_patient_age_operator_right_side', value)
                }
                disabled={watch('condition_patient_age_operator_right_side') === null}
                value={ctrlProps?.field?.value}
              />
            </>
          )}
        />
        <ControlledText
          percentWith={20}
          control={control}
          name="condition_patient_age_operator_right_side_input"
        />
      </Stack>
    );
  };

  const renderTherapyFillCycleCondition = (): JSX.Element | null => {
    return (
      <Stack direction="row" alignItems="baseline" spacing={1}>
        <Typography>Therapy Fill Cycle</Typography>
        <Controller
          name="condition_therapy_fill_cycle_operator_left_side"
          control={control}
          xs={{ width: '20%' }}
          render={(ctrlProps: any, ...restField) => (
            <>
              <MultiSelect
                {...restField}
                options={ageOperatorOptions}
                className={classes.inputField}
                onChange={(value: any) =>
                  setValue('condition_therapy_fill_cycle_operator_left_side', value)
                }
                value={ctrlProps?.field?.value}
              />
            </>
          )}
        />
        <ControlledText
          percentWith={20}
          control={control}
          name="condition_therapy_fill_cycle_operator_left_side_input"
          validations={{ required: true }}
          inputMetaData={{ touched: Boolean(errors.name), error: RequiredInputErrorMessage }}
        />
        <Controller
          name="condition_therapy_fill_cycle_operator_right_side"
          control={control}
          xs={{ width: '20%' }}
          render={(ctrlProps: any, ...restField) => (
            <>
              <MultiSelect
                {...restField}
                options={ageOperatorOptions}
                className={classes.inputField}
                onChange={(value: any) =>
                  setValue('condition_therapy_fill_cycle_operator_right_side', value)
                }
                value={ctrlProps?.field?.value}
              />
            </>
          )}
        />
        <ControlledText
          percentWith={20}
          control={control}
          name="condition_therapy_fill_cycle_operator_right_side_input"
        />
      </Stack>
    );
  };

  const renderPatientPrivacyDocumentsUploadedCondition = (): JSX.Element | null => {
    return (
      <Stack direction="row" alignItems="baseline" spacing={1}>
        <Typography>
          Patient Documents Uploaded? - Welcome Kit And Patient Privacy Document
        </Typography>
        <Controller
          name="condition_patient_privacy_documents_uploaded"
          control={control}
          render={(ctrlProps: any, ...restField) => (
            <TextField
              {...restField}
              select
              variant="standard"
              onChange={e =>
                setValue('condition_patient_privacy_documents_uploaded', e.target.value)
              }
              className={classes.inputField}
              value={ctrlProps?.field?.value}
            >
              {yesNoOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Stack>
    );
  };

  const renderTherapyGPICondition = (length: number): JSX.Element | null => {
    return (
      <Stack direction="row" alignItems="baseline" spacing={1}>
        <Typography>Therapy GPI {length}</Typography>
        <Controller
          name={`condition_therapy_gpi${length}_constraint`}
          control={control}
          render={(ctrlProps: any, ...restField) => (
            <TextField
              {...restField}
              inputProps={{ maxLength: length }}
              variant="standard"
              onChange={e => setValue(`condition_therapy_gpi${length}_constraint`, e.target.value)}
              className={classes.inputField}
              value={ctrlProps?.field?.value}
            />
          )}
        />
      </Stack>
    );
  };
  const renderSiteCondition = (): JSX.Element | null => {
    return (
      <Stack direction="row" alignItems="baseline" spacing={1}>
        <Typography>If site</Typography>
        <Controller
          name="condition_operator_eq_customers_ids"
          control={control}
          render={(ctrlProps: any, ...restField) => (
            <>
              <MultiSelect
                {...restField}
                options={eqNotEqOperatorOptions}
                className={classes.inputField}
                onChange={(value: any) => setValue('condition_operator_eq_customers_ids', value)}
                value={ctrlProps?.field?.value}
              />
            </>
          )}
        />
        <Controller
          name="condition_customers_ids"
          control={control}
          render={(ctrlProps: any, ...restField) => (
            <>
              <MultiSelect
                {...restField}
                isMulti
                options={sitesOptions}
                className={classes.inputField}
                onChange={(changedValue: IGenericOption[]) => {
                  ctrlProps?.field?.onChange(changedValue);
                  onChangeSiteOptions(changedValue);
                  setValue('condition_customers_ids', changedValue);
                }}
                value={ctrlProps?.field?.value}
              />
            </>
          )}
        />
      </Stack>
    );
  };

  const renderQuestionAction = (
    questionId: number,
    allowToRepeatAction = false,
  ): JSX.Element | null => (
    <Stack direction="row" spacing={1} alignItems="baseline">
      <Typography>Show/Hide FC Question</Typography>
      <Controller
        name={`show_hide_question_${questionId}_option_selected`}
        control={control}
        render={(ctrlProps: any, ...restField) => (
          <TextField
            {...restField}
            select
            variant="standard"
            onChange={e => {
              setValue(`show_hide_question_${questionId}_option_selected`, e.target.value);
            }}
          >
            {fcQuestionsShowHideOptions.map(option => (
              <MenuItem
                key={`show_hide_question_${questionId}_option_selected_${option.value}`}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      />

      <Controller
        name={`show_hide_question_${questionId}`}
        control={control}
        render={(ctrlProps: any, ...restField) => (
          <TextField
            {...restField}
            select
            variant="standard"
            value={ctrlProps?.field?.value}
            onChange={e => setValue(`show_hide_question_${questionId}`, e.target.value)}
          >
            {fcQuestions.map(option => (
              <MenuItem
                key={`show_hide_question_${questionId}_${option.value}`}
                value={option.value}
                disabled={Object.entries(formStateValues).some(([key, formValue]) => {
                  if (key.includes('question') && formValue === option.value) {
                    return true;
                  }
                  return false;
                })}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      />

      {allowToRepeatAction && (
        <Button
          variant="outlined"
          onClick={() => {
            const newQuestionId = getNextOrderForQuestionArray(shownActions);
            // Add new Action Form for question in the next order available
            setShownActions({
              ...shownActions,
              [`question_${newQuestionId}`]: {
                shown: true,
                order: newQuestionId,
                id: newQuestionId,
              },
            });
          }}
        >
          Add this Action Again
        </Button>
      )}
    </Stack>
  );

  const renderCreateDcTaskAction = (): JSX.Element | null => (
    <Stack direction="column" spacing={1} alignItems="baseline">
      <Grid container className={classes.tableHeader}>
        <Grid item xs={12}>
          <TreeSelect
            label="Select Clinical Data Items"
            onChange={value => {
              setSelectedClinicalDataItems(
                clinicalDataItemsToSelect
                  .flatMap(({ children }) => children)
                  .filter(child => value.includes(child.value))
                  .map(child => {
                    return {
                      value: child.value,
                      label: `${child.parentTitle} - ${child.title}`,
                      required: true,
                    };
                  }),
              );
            }}
            onBlur={() => {}}
            watchValue
            sx={{ width: '100px' }}
            isSuggestedOptions={false}
            multi
            meta={{}}
            disabled={false}
            treeData={clinicalDataItemsToSelect}
          />
        </Grid>
        {selectedClinicalDataItems.map(selectedClinicalDataItem => (
          <>
            <Grid item xs={6}>
              <Typography>{selectedClinicalDataItem.label}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Stack direction="row" spacing={1} alignItems="baseline">
                <InputLabel>Required</InputLabel>
                <ControlledCheckbox
                  defaultValue={false}
                  control={control}
                  name={`list_clinical_data_item_required_${selectedClinicalDataItem.value}`}
                  inputMetaData={{
                    touched: Boolean(errors.name),
                    error: RequiredInputErrorMessage,
                  }}
                />
              </Stack>
            </Grid>
          </>
        ))}
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="baseline" spacing={1}>
            <Typography>Frequency</Typography>
            <Controller
              name="dc_action_frequency_value"
              control={control}
              render={(ctrlProps: any, ...restField) => (
                <TextField
                  {...restField}
                  variant="standard"
                  type="number"
                  onChange={e => setValue('dc_action_frequency_value', e.target.value)}
                  className={classes.inputField}
                  value={ctrlProps?.field?.value}
                />
              )}
            />
            <Controller
              name="dc_action_frequency_unit"
              control={control}
              render={(ctrlProps: any, ...restField) => (
                <TextField
                  {...restField}
                  select
                  variant="standard"
                  value={ctrlProps?.field?.value}
                  onChange={e => setValue('dc_action_frequency_unit', e.target.value)}
                >
                  <MenuItem key="dc_action_frequency_unit_monthly" value="day">
                    Days
                  </MenuItem>
                  <MenuItem key="dc_action_frequency_unit_weekly" value="week">
                    Weeks
                  </MenuItem>
                  <MenuItem key="dc_action_frequency_unit_monthly" value="month">
                    Months
                  </MenuItem>
                </TextField>
              )}
            />
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );

  const renderCreateIntTaskAction = (): JSX.Element | null => (
    <Stack direction="row" spacing={1} alignItems="baseline">
      <Controller
        name="create_int_task_category_selected"
        control={control}
        defaultValue={intCategoriesOptions[0].value || null}
        render={(ctrlProps: any, ...restField) => (
          <>
            <Typography>Category</Typography>
            <TextField
              {...restField}
              select
              variant="standard"
              onChange={e => setValue('create_int_task_category_selected', e.target.value)}
              value={ctrlProps?.field?.value}
            >
              {intCategoriesOptions.map(option => (
                <MenuItem
                  key={`create_int_task_category_option_selected_${option.value}`}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </>
        )}
      />
      <Controller
        name="create_int_task_type_selected"
        control={control}
        defaultValue={intTypeOptions[0].value || null}
        render={(ctrlProps: any, ...restField) => (
          <>
            <Typography>Type</Typography>
            <TextField
              {...restField}
              select
              variant="standard"
              onChange={e => setValue('create_int_task_type_selected', e.target.value)}
              value={ctrlProps?.field?.value}
            >
              {intTypeOptions.map(option => (
                <MenuItem
                  key={`create_int_task_type_option_selected_${option.value}`}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </>
        )}
      />
    </Stack>
  );

  const renderCreateGenericTaskAction = (taskId: number): JSX.Element | null => {
    const currentValue = watch(`create_task_${taskId}_type_selected`) || null;
    return (
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" spacing={1} alignItems="baseline">
            <Typography>Create Task</Typography>
            <Controller
              name={`create_task_${taskId}_type_selected`}
              control={control}
              defaultValue={taskTypesToCreateOptions[0].value || null}
              render={(ctrlProps: any, ...restField) => (
                <>
                  <TextField
                    {...restField}
                    select
                    variant="standard"
                    onChange={e => {
                      const selectedTaskType = e.target.value;
                      setValue(`create_task_${taskId}_type_selected`, selectedTaskType);
                    }}
                    value={ctrlProps?.field?.value}
                  >
                    {taskTypesToCreateOptions.map(option => (
                      <MenuItem
                        key={`create_task_${taskId}_type_option_selected${option.value}`}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </>
              )}
            />
          </Stack>
        </Grid>
        {(() => {
          let taskTypeSpecificForm = () => <></>;
          switch (currentValue) {
            case TaskTypesEnum.INT: {
              taskTypeSpecificForm = renderCreateIntTaskAction;
              break;
            }
            case TaskTypesEnum.DC: {
              taskTypeSpecificForm = renderCreateDcTaskAction;
              break;
            }
            default:
              break;
          }
          return (
            <Grid item xs={12} sx={{ mt: 1 }}>
              {taskTypeSpecificForm()}
            </Grid>
          );
        })()}
      </Grid>
    );
  };

  const onClickAddCondition = () => {
    setShowAddConditionDropdown(true);
  };

  const onClickAddAction = () => {
    setShowAddActionDropdown(true);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit(onSubmit, onErrors)}>
          <Typography variant="h5" className={classes.mainTitle}>
            Add Rule
          </Typography>
          <Divider className={classes.sectionDivider} />
          <ControlledText
            control={control}
            validations={{ required: true }}
            name="name"
            label="Rule Name"
            percentWith={100}
            inputMetaData={{ touched: Boolean(errors.name), error: RequiredInputErrorMessage }}
          />

          {/* Triggers Section */}
          <Box component="section" className={classes.sectionContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className={classes.sectionTitle}>Define Trigger</Typography>
              </Grid>
              <Grid item xs={12} className={classes.sectionBody}>
                <Stack direction="row" spacing={1} alignItems="baseline">
                  <Typography>For</Typography>
                  <ControlledDropdown<EventTypeEnum>
                    label=""
                    name="selected_trigger"
                    percentWith={100}
                    control={control}
                    validations={{ required: true }}
                    onChange={selectedTrigger => {
                      setShownConditions(
                        Object.fromEntries(
                          Object.entries(shownConditions).filter(([id, shownCondition]) => {
                            if (shownCondition.shown) {
                              const foundCondition = conditionsToAdd.find(
                                ({ value: conditionName }) => conditionName === id,
                              );
                              return foundCondition.validEventTypes?.includes(selectedTrigger);
                            }
                            return false;
                          }),
                        ) as Record<ConstraintTypeNames, DynamicControlledInput>,
                      );
                    }}
                    options={triggersOptions}
                    defaultValue={null}
                    inputMetaData={{
                      touched: Boolean(errors.selected_trigger),
                      error: RequiredInputErrorMessage,
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Box>
          {/* /Triggers Section */}

          {/* Conditions Section */}
          <Box component="section" className={classes.sectionContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className={classes.sectionTitle}>Define Conditions</Typography>
              </Grid>
              <Grid item xs={12} className={classes.sectionBody}>
                <Grid container rowSpacing={2}>
                  {Object.entries(shownConditions)
                    .filter(([_, { shown }]) => shown === true)
                    .sort(([_1, a], [_2, b]) => {
                      return Number(a.order) < Number(b.order) ? -1 : 1;
                    })
                    .map(([constraintKeyName, _], index, array) => {
                      let renderConditionForm = null;
                      const renderChipAtBeginning = index > 0 && index < array.length;
                      switch (constraintKeyName as ConstraintTypeNames) {
                        case 'customer_id_filter':
                          renderConditionForm = renderSiteCondition();
                          break;
                        case 'therapy_dispensing_status':
                          renderConditionForm = renderDispenseCondition();
                          break;
                        case 'therapy_disease_state':
                          renderConditionForm = renderTherapyDiseaseStateCondition();
                          break;
                        case 'therapy_clinical_support_status':
                          renderConditionForm = renderClinicalSupportCondition();
                          break;
                        case 'therapy_type':
                          renderConditionForm = renderTherapyTypeCondition();
                          break;
                        case 'patient_welcome_kit':
                          renderConditionForm = renderPatientPreferenceForWelcomeKitCondition();
                          break;
                        case 'patient_packaging':
                          renderConditionForm = renderPatientPreferenceForMedPacking();
                          break;
                        case 'patient_med_sync':
                          renderConditionForm = renderPatientPreferenceForMedSync();
                          break;
                        case 'patient_gender':
                          renderConditionForm = renderPatientGenderCondition();
                          break;
                        case 'patient_age':
                          renderConditionForm = renderPatientAgeCondition();
                          break;
                        case 'patient_special_population':
                          renderConditionForm = renderSpecialPopulationCondition();
                          break;
                        case 'welcome_kit_and_patient_privacy_docs_uploaded':
                          renderConditionForm = renderPatientPrivacyDocumentsUploadedCondition();
                          break;
                        case 'therapy_fill_cycle':
                          renderConditionForm = renderTherapyFillCycleCondition();
                          break;
                        case 'therapy_gpi10_constraint':
                          renderConditionForm = renderTherapyGPICondition(10);
                          break;
                        case 'therapy_gpi12_constraint':
                          renderConditionForm = renderTherapyGPICondition(12);
                          break;
                        case 'therapy_gpi14_constraint':
                          renderConditionForm = renderTherapyGPICondition(14);
                          break;
                        default:
                          break;
                      }
                      return (
                        <>
                          {renderChipAtBeginning && (
                            <Grid item xs={12}>
                              <Chip label="And" className={classes.chip} />
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <Stack direction="row" justifyContent="space-between">
                              {renderConditionForm}
                              <Button
                                className={classes.removeButton}
                                onClick={() => {
                                  onClickRemoveCondition(constraintKeyName as ConstraintTypeNames);
                                  setValue(`condition_${constraintKeyName}`, null);
                                }}
                              >
                                <EditDelete />
                              </Button>
                            </Stack>
                          </Grid>
                        </>
                      );
                    })}
                  {showAddConditionDropdown && (
                    <Grid item xs={12}>
                      {renderAddConditionSection()}
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        onClickAddCondition();
                      }}
                    >
                      + Condition
                    </Button>
                  </Grid>
                  {!!beforeSendErrors.condition && (
                    <Grid item xs={12} className={classes.errorMessage}>
                      <Typography>{beforeSendErrors.condition}</Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
          {/* /Conditions Section */}

          {/* Actions Section */}
          <Box component="section" className={classes.sectionContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className={classes.sectionTitle}>Define Actions</Typography>
              </Grid>
              <Grid item xs={12} className={classes.sectionBody}>
                <Grid container rowSpacing={2}>
                  {Object.entries(shownActions)
                    .filter(([_, { shown }]) => shown === true)
                    .sort(([_1, a], [_2, b]) => {
                      return Number(a.order) < Number(b.order) ? -1 : 1;
                    })
                    .map(([actionKeyName, { id }], index, array) => {
                      let renderActionForm = null;
                      const renderChipAtBeginning = index > 0 && index < array.length;
                      switch (actionKeyName as string | ActionTypeNames) {
                        default:
                          if (actionKeyName.includes(ActionTypeNamesEnum.ShowHideQuestion)) {
                            const questionId = id !== null ? Number(id) : 1;
                            renderActionForm = renderQuestionAction(questionId, false);
                          }
                          if (actionKeyName.includes(ActionTypeNamesEnum.CreateTask)) {
                            const taskId = id !== null ? Number(id) : 1;
                            renderActionForm = renderCreateGenericTaskAction(taskId);
                          }
                          break;
                      }
                      return (
                        <>
                          {renderChipAtBeginning && (
                            <Grid item xs={12}>
                              <Chip label="And" className={classes.chip} />
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <Stack direction="row" justifyContent="space-between">
                              {renderActionForm}
                              <Button
                                className={classes.removeButton}
                                onClick={() => {
                                  onClickRemoveAction(actionKeyName as ActionTypeNames);
                                }}
                              >
                                <EditDelete />
                              </Button>
                            </Stack>
                          </Grid>
                        </>
                      );
                    })}
                  {showAddActionDropdown && (
                    <Grid item xs={12}>
                      {renderAddActionSection()}
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        onClickAddAction();
                      }}
                    >
                      + Action
                    </Button>
                  </Grid>
                  {!!beforeSendErrors.action && (
                    <Grid item xs={12} className={classes.errorMessage}>
                      <Typography>{beforeSendErrors.action}</Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
          {/* /Actions Section */}
          <Divider className={classes.sectionDivider} />

          {/* Rule Basic Data */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={4}>
                {formMode === FormModeEnum.Edit && (
                  <Controller
                    name="status"
                    control={control}
                    render={(ctrlProps: any, ...restField) => (
                      <TextField
                        {...restField}
                        select
                        label="Status"
                        variant="standard"
                        onChange={e => setValue('status', e.target.value)}
                        className={classes.inputField}
                        value={ctrlProps?.field?.value}
                      >
                        {statuses.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                )}

                <ControlledDatePicker
                  defaultValue={null}
                  name="effective_date"
                  control={control}
                  autoComplete={false}
                  validations={{ required: true }}
                  inputMetaData={{
                    touched: Boolean(errors.effective_date),
                    error: RequiredInputErrorMessage,
                  }}
                  label="Effective Date"
                />

                <ControlledDatePicker
                  defaultValue={null}
                  name="expiration_date"
                  control={control}
                  autoComplete={false}
                  label="Expiration Date"
                />
              </Stack>
            </Grid>
          </Grid>
          {/* /Rule Basic Data */}

          <Divider className={classes.sectionDivider} />
          {/* Form Footer */}
          <Stack direction="row" spacing={2} sx={{ float: 'right', marginTop: '10px' }}>
            <Button
              variant="text"
              type="button"
              onClick={() => {
                if (props.onCloseModal) {
                  props.onCloseModal();
                }
              }}
            >
              Cancel
            </Button>
            <Button variant="contained" type="submit">
              Save
            </Button>
          </Stack>
          {/* /Form Footer */}
        </form>
      </Grid>
    </Grid>
  );
};
