import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Box, DialogTitle, Modal, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DISPENSING_STATUSES, CSL, FC, INT } from 'constants';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { usePatient } from 'hooks/usePatient';
import {
  getChecklistData,
  reenrollPatient,
  refreshPatientTherapiesAndTasks,
} from 'actions/action-patient';
import HTTP from 'services/http';
import { notifySuccess } from 'actions/action-notifications';
import PatientReenrollTherapyTable from './patient-reenroll-therapy-table';
import PatientReenrollTherapyHeader from './patient-reenroll-therapy-header';
import { THERAPY_ADMIN_STATUS_DISCONTINUED, THERAPY_ADMIN_STATUS_NO_GO } from '../../../constants';
import { PatientTherapiesClient } from '../../../clients/patient-therapies';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    minWidth: 700,
    maxHeight: 800,
    backgroundColor: theme.palette.primary.white,
    borderRadius: 5,
    overflow: 'auto',
  },
  reenrollFormTitle: {
    backgroundColor: theme.palette.primary.bluesky,
    marginBottom: theme.spacing(1),
  },
  reenrollFormDescription: {
    marginTop: 15,
    marginBottom: 15,
  },
  reenrollFormContent: {
    'marginLeft': theme.spacing(2),
    'marginRight': theme.spacing(4),
    '& i': {
      marginRight: theme.spacing(0.5),
    },
  },
}));

const PatientReenrollForm = props => {
  const { open, clinic, handleClose } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,

    formState: { errors },
  } = useForm();

  const patient = useSelector(state => state.patient);
  const therapies = useSelector(state => Object.values(state?.therapies?.data ?? {}));
  const tasks = useSelector(state => Object.values(state?.tasks?.data ?? {}));
  const filteredTherapies = React.useMemo(() => {
    return therapies.filter(t =>
      tasks.some(
        s =>
          s.therapy_id === t.id &&
          s.status_id_prior_to_discharge !== null &&
          t.managing_clinic_id === clinic.id &&
          t.administration_status !== THERAPY_ADMIN_STATUS_DISCONTINUED &&
          t.administration_status !== THERAPY_ADMIN_STATUS_NO_GO &&
          (t.dispensing_status !== DISPENSING_STATUSES.OPT_OUT ||
            (s.taskType !== CSL && s.taskType !== FC)),
      ),
    );
  }, [therapies, tasks]);

  const { patientFirstLastName } = usePatient(patient);
  const [selectedTasks, setSelectedTasks] = useState({});
  const [therapyNeedsbyDates, setTherapyNeedsbyDates] = useState({});

  const getTasksToReenroll = async key => {
    const listOfTasks = [];
    for (const t of Object.values(selectedTasks[key])) {
      if (t.taskType === INT) {
        const isRsInt = await PatientTherapiesClient.isRsIntervention(t.id);
        // exclude RS intervention tasks
        if (isRsInt?.data?.result === false) {
          listOfTasks.push({
            id: t.id,
            taskType: t.taskType,
            followUpDate: t.followup_dt,
            statusIdPriorToDischarge: t.status_id_prior_to_discharge,
          });
        }
      } else {
        listOfTasks.push({
          id: t.id,
          taskType: t.taskType,
          followUpDate: t.followup_dt,
          statusIdPriorToDischarge: t.status_id_prior_to_discharge,
        });
      }
    }
    return listOfTasks;
  };

  const onSubmit = async () => {
    handleClose();
    const tempTherapies = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(therapyNeedsbyDates)) {
      if (!tempTherapies[key]) {
        tempTherapies[key] = {
          id: key,
          needsbyDate: therapyNeedsbyDates[key],
          tasks: await getTasksToReenroll(key),
        };
      }
    }

    const payload = {
      therapies: Object.values(tempTherapies),
    };

    const response = await HTTP.patch(
      `/patients/${patient.id}/clinicId/${clinic.id}/reenroll`,
      payload,
      {},
    );
    dispatch(reenrollPatient(clinic.id, clinic.name, response?.data?.updated_patient));
    dispatch(refreshPatientTherapiesAndTasks(patient.id));
    dispatch(getChecklistData(patient.id));
    dispatch(notifySuccess('Patient re-enrolled successfully'));
  };

  return (
    <Modal open={open} className={classes.modal} disableAutoFocus>
      <div className={classes.modalContent}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle className={classes.reenrollFormTitle}>
            {`Re-Enroll ${patientFirstLastName} to ${clinic.name}`}
          </DialogTitle>

          <div className={classes.reenrollFormContent}>
            <Typography>
              <Box className={classes.reenrollFormDescription}>
                Below you can select which therapies and tasks you want to reactivate.
              </Box>
            </Typography>
          </div>

          {filteredTherapies.map(t => (
            <div className={classes.reenrollFormContent}>
              <PatientReenrollTherapyHeader
                therapyId={t.id}
                updateTherapyNeedsbyDate={needsbyDate => {
                  setTherapyNeedsbyDates(prev => ({
                    ...prev,
                    [t.id]: needsbyDate,
                  }));
                }}
                selectedTasks={selectedTasks[t.id]}
                control={control}
                errors={errors}
              />
              <PatientReenrollTherapyTable
                therapyId={t.id}
                therapyDispensingStatus={t.dispensing_status}
                control={control}
                errors={errors}
                handleRowSelection={selectedRows => {
                  setSelectedTasks(prevSelectedRows => ({
                    ...prevSelectedRows,
                    [t.id]: selectedRows,
                  }));
                }}
              />
            </div>
          ))}

          <ConfirmationPanel submitButtonText="RE-ENROLL" handleCancel={handleClose} />
        </form>
      </div>
    </Modal>
  );
};

export default PatientReenrollForm;
