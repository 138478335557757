import React, { useState, Fragment } from 'react';
import { compose } from 'recompose';
import { connect, useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import JsonForm from 'components/dynamic-form/json-form';
import { editTasks } from 'actions/action-tasks';
import { addTaskOldValues } from 'services/utils/task-service';
import { taskAbbreviationMap, DUR, DC } from 'constants/index';
import { fetchResourceLink } from 'actions/action-resource-links';
import { workListChanged } from 'actions/action-patient';
import { parseNoteToDb, getNotesHigestId, getNoteTagId } from 'services/utils/note-service';
import { fetchNewNotes } from 'actions/action-notes';
import { isDcTransitioningToIntervening } from 'services/utils/data-collect';
import { minBy } from 'lodash';
import { DurStatus } from 'interfaces/enums/TaskStatuses/DrugUtilizationReviewStatus';
import { windowFeatureIsEnabled } from 'config/window-features';
import {
  bundleInteractionsForDur,
  interventionsHaveRecommendationsAndOutcomesOrUnchecked,
} from '../helpers';
import providerBuilder from '../providers/provider-builder';
import taskProviderHoc from './task-form-hoc';
import FollowUpDataCollectModal from './follow-up-data-collect-modal';
import { PatientTherapiesClient } from '../../../clients/patient-therapies';
import {
  TherapyWorkflowLabel,
  SpecialtyTherapiesWf,
  DispensingStatusValue,
} from '../../../constants/enums';

function getPayload(
  updatedTask,
  selectedPatientId,
  task,
  taskType,
  formsState,
  hasDURIntervention = false,
  markAsReviewed,
) {
  let payload = addTaskOldValues(
    {
      ...updatedTask,
      id: task.id,
      patient_id: selectedPatientId,
      therapy_id: task.therapy_id,
      taskType,
      service_group_id: task.service_group_id,
    },
    task,
  );

  const notes = [];
  if (updatedTask.note) {
    const parsedNote = parseNoteToDb({ note_text: updatedTask.note });
    const typeId = getNoteTagId(taskType);
    notes.push({
      patient_id: selectedPatientId,
      note_type_id: 1,
      note_text: parsedNote.note_text,
      is_pinned: 0,
      mentions: parsedNote.mentions,
      tags: [
        {
          tag_type_id: typeId,
          resource_id: task.id,
        },
      ],
    });
  }

  if (formsState && hasDURIntervention) {
    const durInterventionForms = bundleInteractionsForDur(formsState);
    if (
      interventionsHaveRecommendationsAndOutcomesOrUnchecked(durInterventionForms) &&
      markAsReviewed
    ) {
      payload = { ...payload, status_id: DurStatus.Reviewed };
    }
    payload = { ...payload, interactions: durInterventionForms };
  }
  return { payload, notes };
}

export const TaskStatusForm = props => {
  const {
    task,
    task: { taskType } = {},
    forceStatusId,
    selectedPatientId,
    onCancel,
    onSubmit,
    editTasks, // eslint-disable-line
    workListChanged, // eslint-disable-line
    fetchResourceLink, // eslint-disable-line
    existingNotes,
    fetchNewNotes, // eslint-disable-line
    tagTypeId,
    tagResourceId,
    tagName,
    fileUploadFormId,
    taskId,
    fileFormName,
    notes,
    drawBorder,
    overrideForceStatusId,
  } = props;

  // const dispatch = useDispatch();
  const data = { ...task, status_id: forceStatusId || task.status_id };
  const providers = providerBuilder(taskType)(data, props);
  const formsState = useSelector(state => state.form);
  const therapiesObj = useSelector(state => state.therapies.data);
  const [followUpDataCollectInfo, setFollowUpDataCollectInfo] = useState(null);
  const [value, setValue] = useState({});

  const disableSubmitAndReview = () => {
    if (formsState && taskType === DUR) {
      const durInterventionForms = bundleInteractionsForDur(formsState);
      return !interventionsHaveRecommendationsAndOutcomesOrUnchecked(durInterventionForms);
    }
    return true;
  };

  return (
    <>
      <JsonForm
        type={taskType}
        formName="therapiesStatus"
        formId={`${taskType}-status-${task.id}`}
        jsonForm={providers.json}
        data={data}
        forceStatusId={forceStatusId}
        providers={providers}
        alwaysEnabled
        useHandleSubmit={taskType === DUR}
        tagTypeId={tagTypeId}
        tagResourceId={tagResourceId}
        tagName={tagName}
        fileUploadFormId={fileUploadFormId}
        taskId={taskId}
        fileFormName={fileFormName}
        notes={notes}
        hideSubmitAndReview={taskType !== DUR}
        disableSubmitAndReview={disableSubmitAndReview}
        drawBorder={drawBorder}
        onSubmit={(updatedTask, markAsReviewed = false) => {
          const update = getPayload(
            {
              ...updatedTask,
              status_id:
                overrideForceStatusId && updatedTask.status_id
                  ? updatedTask.status_id
                  : forceStatusId,
            },
            selectedPatientId,
            task,
            taskType,
            formsState,
            taskType === DUR,
            markAsReviewed,
          );
          let currentTherapy = {};
          if (
            therapiesObj &&
            Object.values(therapiesObj).length &&
            data.taskType === DC &&
            data.therapy_id
          ) {
            currentTherapy = Object.values(therapiesObj).find(t => t.id === data.therapy_id);
          }
          PatientTherapiesClient.getTherapyWorkflow(
            currentTherapy.diagnosis_code,
            currentTherapy?.specialty_type ?? null,
          ).then(response => {
            const workflowLabel = TherapyWorkflowLabel[response.data?.therapyWorkflow] ?? '';
            if (updatedTask.data_collect_followup && !isDcTransitioningToIntervening(updatedTask)) {
              if (
                workflowLabel !== SpecialtyTherapiesWf.SP ||
                currentTherapy.dispensing_status_id !== DispensingStatusValue.OptOut ||
                !windowFeatureIsEnabled('task_firing_based_on_dispensing_status')
              ) {
                setValue(update);
                const assessmentDates = updatedTask.data_collect
                  .map(dcItem => dcItem.assessmentDate)
                  .filter(assessDate => !!assessDate);
                const minAssessmentDate = assessmentDates.length
                  ? minBy(assessmentDates, assessDateArborDate => Date.parse(assessDateArborDate))
                  : undefined;
                setFollowUpDataCollectInfo({
                  open: true,
                  dataCollectedDate: updatedTask.data_collected_date,
                  followUpMinAssessmentDate: minAssessmentDate,
                  therapyDuration: updatedTask.therapy_duration,
                });
                return;
              }
            }
            editTasks(selectedPatientId, [update.payload], null, update.notes).then(result => {
              if (onSubmit) {
                const resultTask = result.payload.data.updated_tasks.find(t => t.id === task.id);
                onSubmit(resultTask);
              }
              workListChanged();
              fetchResourceLink(
                Object.keys(taskAbbreviationMap).find(it => taskAbbreviationMap[it] === taskType),
                task.id,
              );

              if (update.notes.length) {
                const { pinnedNoteHighestId, nonPinnedNoteHighestId } =
                  getNotesHigestId(existingNotes);
                if (pinnedNoteHighestId > nonPinnedNoteHighestId) {
                  fetchNewNotes({
                    patient_id: selectedPatientId,
                    group_by_tag: true,
                    fetchFromId: pinnedNoteHighestId,
                  });
                } else {
                  fetchNewNotes({
                    patient_id: selectedPatientId,
                    group_by_tag: true,
                    fetchFromId: nonPinnedNoteHighestId,
                  });
                }
              }
            });
          });
        }}
        onCancel={onCancel}
      />

      {followUpDataCollectInfo && followUpDataCollectInfo.open && (
        <FollowUpDataCollectModal
          therapyId={task.therapy_id}
          dataCollectedDate={followUpDataCollectInfo.dataCollectedDate}
          minAssessmentDate={followUpDataCollectInfo.followUpMinAssessmentDate}
          therapyDuration={followUpDataCollectInfo.therapyDuration}
          onSubmit={values => {
            const update = {
              ...value,
              payload: {
                ...value.payload,
                ...values,
              },
            };
            setFollowUpDataCollectInfo(null);
            return editTasks(selectedPatientId, [update.payload], null, update.notes).then(() => {
              workListChanged();
              fetchResourceLink(
                Object.keys(taskAbbreviationMap).find(it => taskAbbreviationMap[it] === taskType),
                task.id,
              );
              if (update.notes.length) {
                const { pinnedNoteHighestId, nonPinnedNoteHighestId } =
                  getNotesHigestId(existingNotes);
                if (pinnedNoteHighestId > nonPinnedNoteHighestId) {
                  fetchNewNotes({
                    patient_id: selectedPatientId,
                    group_by_tag: true,
                    fetchFromId: pinnedNoteHighestId,
                  });
                } else {
                  fetchNewNotes({
                    patient_id: selectedPatientId,
                    group_by_tag: true,
                    fetchFromId: nonPinnedNoteHighestId,
                  });
                }
              }
            });
          }}
          open
          onClose={() => setFollowUpDataCollectInfo(null)}
        />
      )}
    </>
  );
};

function mapStateToProps(state, props) {
  const { auth } = state;
  const formValues = getFormValues(`${props.task.taskType}-status-${props.task.id}`)(state);
  return {
    auth,
    formValues,
    existingNotes: state.notes,
    selectedPatientId: state.selectedPatientId,
  };
}

export default compose(
  connect(mapStateToProps, {
    editTasks,
    workListChanged,
    fetchResourceLink,
    fetchNewNotes,
  }),
  taskProviderHoc,
)(TaskStatusForm);
